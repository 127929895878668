<template>
  <div>
    <header-title link="Dashboard">
      <h3 slot="title">
        외부 솔루션 연동 관리
        <tooltip
          :text="`나의 큐픽 계정과 연동되어있는 솔루션 계정 정보를 확인할 수 있습니다.<br/>
          ･ 솔루션과 연동한 이후에는 해당 솔루션으로 사용할 사이트를 지정할 수 있습니다.<br/>
          ･ 지정된 사이트는 다른 사이트로 변경할 수 없으니 해당 솔루션 계정에 사용하는 사이트가 맞는지 확인해 주시기 바랍니다.<br/>
          ･ 연동 해제는 해당 솔루션 관리자에서 해제 처리해 주시기 바랍니다.`"
        />
      </h3>
    </header-title>
    <div class="main">
      <div class="main__container">
        <div v-if="!solutionList.length" class="data-empty">
          <img src="@/assets/images/admin/icon_empty.svg" />
          <p>연동된 솔루션 정보가 없습니다.</p>
        </div>
        <div class="bothLayout" v-else>
          <div
            class="bothLayout__item"
            v-for="(solution, index) in solutionList"
            :key="index"
          >
            <div class="userSite">
              <dl v-if="solution.userSite">
                <dt>
                  <img
                    v-if="solution.userSite?.attachFiles?.url"
                    :src="solution.userSite?.attachFiles?.url"
                  />
                  <span v-else>{{ solution.userSite.name.slice(0, 1) }}</span>
                </dt>
                <dd
                  class="cursor userSiteName ga_a_203"
                  @click.stop="moveSite(solution.userSite.id)"
                >
                  {{ solution.userSite.name }}
                </dd>
              </dl>
              <div class="DAV-dropdown" v-else>
                <button
                  type="button"
                  class="userSite__button"
                  @click.stop="showUserSite(index)"
                >
                  연동할 사이트 선택 <i class="xi-angle-right-min"></i>
                </button>
                <div :class="setDropdownClass" v-show="userSiteLayer[index]">
                  <ul v-click-outside:hideUserSite="index">
                    <li v-for="(site, i) in userSiteList" :key="i">
                      <button
                        type="button"
                        @click="selectUserSite(site.id, solution.id)"
                      >
                        {{ site.name }}
                      </button>
                    </li>
                  </ul>
                  <div v-if="getDeviceType === 'mobile'" class="dimmed"></div>
                </div>
              </div>
            </div>
            <div class="userSolution">
              <dl class="userSolution__item">
                <dt>솔루션명</dt>
                <dd>
                  <img
                    src="@/assets/images/common/bi_makeshop.svg"
                    v-if="solution.solutionName === '메이크샵'"
                    style="height: 18px"
                  />
                  <img
                    src="@/assets/images/common/bi_mysoho.svg"
                    v-if="solution.solutionName === '마이소호'"
                    style="height: 18px"
                  />
                  <!-- {{ solution.solutionName }} -->
                  <span>(ID : {{ solution.solutionUserId }})</span>
                </dd>
              </dl>
              <dl class="userSolution__item">
                <dt>연동일시</dt>
                <dd>{{ solution.createdAt | date }}</dd>
              </dl>
              <dl class="userSolution__item userSolution__item--break">
                <dt>API Key</dt>
                <dd>{{ solution.apikey }}</dd>
              </dl>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">ⓒ QPICK</div>
  </div>
</template>

<script>
import {createNamespacedHelpers} from "vuex";

const ModuleAlert = createNamespacedHelpers("ModuleAlert");
const ModuleConfirm = createNamespacedHelpers("ModuleConfirm");
const ModuleAdmin = createNamespacedHelpers("ModuleAdmin");
const ModuleUserSite = createNamespacedHelpers("ModuleUserSite");

export default {
  data() {
    return {
      userId: null,
      siteId: null,
      userSolutionId: null,
      solutionList: {},
      userSiteList: {},
      userSiteLayer: {},
      availableChooseSiteList: []
    };
  },
  watch: {
    // 솔루션 연동정보 목록
    getUserSolutionListData(data) {
      this.solutionList = data.list;

      for (let i in this.solutionList) {
        for (let site of this.userSiteList) {
          if (this.solutionList[i].id === site.userSolutionId) {
            this.$set(this.solutionList[i], "userSite", site);
          }
        }
      }
    },
    // 사이트 정보 목록
    getUserSiteListData(data) {
      this.userSiteList = data.list;

      //선택가능한 사이트 목록 추가
      this.userSiteList.forEach(item => {
        if (!item.userSolution) {
          this.availableChooseSiteList.push(item);
        }
      });

      this.getUserSolutionList();
    },
    // 사이트 정보 수정
    getUserSiteUpdateData() {
      this.setAlert({
        isVisible: true,
        message: `연동이 완료되었습니다.`,
        event: "siteUpdate"
      });
    }
  },
  computed: {
    // class binding - 드롭다운 메뉴
    setDropdownClass() {
      return {
        "DAV-dropdown__popup": this.getDeviceType === "mobile",
        "DAV-dropdown__list": this.getDeviceType === "desktop"
      };
    },

    ...ModuleAdmin.mapGetters(["getDeviceType"]),
    ...ModuleUserSite.mapGetters([
      "getUserSolutionListData",
      "getUserSiteListData",
      "getUserSiteUpdateData"
    ])
  },
  methods: {
    // 사이트 정보 목록 레이어 show
    showUserSite(key) {
      if (this.availableChooseSiteList.length === 0) {
        // 연동할 사이트가 없는 경우
        this.setConfirm({
          isVisible: true,
          message: `해당 솔루션으로 생성된 사이트가 없습니다.<br />사이트를 먼저 추가하고 다시 시도해주세요.`,
          messageBtn: "사이트 추가하기",
          event: () => this.$router.push("/introduction/theme-list?id=3")
        });
      } else {
        this.userSiteLayer = {};
        this.$set(this.userSiteLayer, key, true);
      }
    },
    // 사이트 정보 목록 레이어 hide
    hideUserSite(key) {
      this.$set(this.userSiteLayer, key, false);
    },
    // 사이트 정보 선택
    selectUserSite(siteId, userSolutionId) {
      this.siteId = siteId;
      this.userSolutionId = userSolutionId;

      // 연동할 사이트가 있는 경우
      this.setConfirm({
        isVisible: true,
        message: `사이트 선택 시 변경할 수 없습니다.<br />선택한 사이트로 연동하겠습니까?`,
        messageBtn: "사이트 연동하기",
        event: () => this.getUserSiteUpdate()
      });
    },
    // 솔루션 연동정보 목록
    getUserSolutionList() {
      let payload = {};
      payload.userId = this.userId;
      payload.sortBy = "-createdAt";

      this.actUserSolutionList(payload).then(() => {});
    },
    // 사이트 정보 목록
    getUserSiteList() {
      let payload = {};
      payload.userId = this.userId;

      this.actUserSiteList(payload).then(() => {});
    },
    // 사이트 정보 수정
    getUserSiteUpdate() {
      let payload = {};
      payload.userId = this.userId;
      payload.siteId = this.siteId;
      payload.userSolutionId = this.userSolutionId;

      this.actUserSiteUpdate(payload).then(response => {
        this.$store.dispatch("ModuleUser/actUserLog", {
          eventName: "사이트 선택",
          requestLocation: "큐픽 > 어드민 > 솔루션 연동 관리",
          solutionName: response.data.solution.name,
          solutionId: response.data.solution.id,
          siteId: this.siteId
        });
      });
    },
    //회원 사이트 테마관리로 이동
    moveSite(id) {
      this.$router.push("/admin/theme/" + id);
    },
    ...ModuleUserSite.mapActions([
      "actUserSolutionList",
      "actUserSiteList",
      "actUserSiteUpdate"
    ]),
    ...ModuleAlert.mapMutations(["setAlert"]),
    ...ModuleConfirm.mapMutations(["setConfirm"])
  },
  mounted() {
    this.userId = localStorage.getItem("userId");
    this.getUserSiteList();
  },
  created() {
    this.$EventBus.$on("alertCancel", event => {
      // alert: 사이트 연동 완료
      if (event === "siteUpdate") {
        this.$router.go(this.$router.currentRoute);
      }
    });
  },
  components: {
    HeaderTitle: () => import("@/components/admin/HeaderTitle")
  }
};
</script>

<style lang="scss" scoped>
.DAV-dropdown {
  &__list {
    top: 40px;
    max-width: 380px;
    li:first-child button {
      background: none;
    }
  }
}
.data-empty {
  background: #f7f9fc;
  border-radius: 8px;
  text-align: center;
  padding: 163px 0 445px 0;
  margin-top: 8px;

  @media screen and (max-width: 1023px) {
    padding: 163px 0 300px 0;
  }

  p {
    font-weight: 500;
    font-size: 14px;
    color: #309574;
    margin-top: 27px;
  }
}
.userSite {
  dl {
    display: flex;
    align-items: center;
  }
  dt {
    flex-shrink: 0;
    width: 72px;
    height: 72px;
    border-radius: 50%;
    margin-right: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    span {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background: #54c7a2;
      font-weight: 500;
      font-size: 28px;
      color: #fff;
    }
  }
  dd {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #484948;
  }
  &__button {
    display: flex;
    align-items: center;
    width: auto;
    height: 40px;
    margin: 16px 0 32px;
    padding: 0 6px 0 12px;
    color: #985fe8;
    font-weight: 500;
    border: 1px solid #985fe8;
    border-radius: 4px;

    i {
      font-size: 20px;
    }
  }
}
.userSolution {
  margin-top: 16px;
  padding-top: 6px;
  border-top: 1px solid #f1f1f1;

  dl {
    display: flex;
    align-items: center;
    margin-top: 4px;
  }
  dt {
    flex-shrink: 0;
    width: 70px;
    margin-right: 10px;
    margin-bottom: auto;
    color: #484948;
    font-weight: 500;
    background-image: url($path + "bg_vline.svg");
    background-repeat: no-repeat;
    background-position: 100% 50%;
    background-size: 1px 12px;
  }
  dd {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    img {
      vertical-align: middle;
    }
    span {
      margin-left: 4px;
      color: #8d8d8d;
    }
  }
  &__item--break dd {
    white-space: pre-wrap;
    word-break: break-all;
  }
}
.footer {
  margin: 40px 0;
  color: #8d8d8d;
  font-weight: 500;
  font-size: 12px;
  text-align: center;
}
.userSiteName:hover {
  text-decoration: underline;
  text-decoration-color: #484948;
}
</style>
