var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('header-title', {
    attrs: {
      "link": "Dashboard"
    }
  }, [_c('h3', {
    attrs: {
      "slot": "title"
    },
    slot: "title"
  }, [_vm._v(" 외부 솔루션 연동 관리 "), _c('tooltip', {
    attrs: {
      "text": "\uB098\uC758 \uD050\uD53D \uACC4\uC815\uACFC \uC5F0\uB3D9\uB418\uC5B4\uC788\uB294 \uC194\uB8E8\uC158 \uACC4\uC815 \uC815\uBCF4\uB97C \uD655\uC778\uD560 \uC218 \uC788\uC2B5\uB2C8\uB2E4.<br/>\n        \uFF65 \uC194\uB8E8\uC158\uACFC \uC5F0\uB3D9\uD55C \uC774\uD6C4\uC5D0\uB294 \uD574\uB2F9 \uC194\uB8E8\uC158\uC73C\uB85C \uC0AC\uC6A9\uD560 \uC0AC\uC774\uD2B8\uB97C \uC9C0\uC815\uD560 \uC218 \uC788\uC2B5\uB2C8\uB2E4.<br/>\n        \uFF65 \uC9C0\uC815\uB41C \uC0AC\uC774\uD2B8\uB294 \uB2E4\uB978 \uC0AC\uC774\uD2B8\uB85C \uBCC0\uACBD\uD560 \uC218 \uC5C6\uC73C\uB2C8 \uD574\uB2F9 \uC194\uB8E8\uC158 \uACC4\uC815\uC5D0 \uC0AC\uC6A9\uD558\uB294 \uC0AC\uC774\uD2B8\uAC00 \uB9DE\uB294\uC9C0 \uD655\uC778\uD574 \uC8FC\uC2DC\uAE30 \uBC14\uB78D\uB2C8\uB2E4.<br/>\n        \uFF65 \uC5F0\uB3D9 \uD574\uC81C\uB294 \uD574\uB2F9 \uC194\uB8E8\uC158 \uAD00\uB9AC\uC790\uC5D0\uC11C \uD574\uC81C \uCC98\uB9AC\uD574 \uC8FC\uC2DC\uAE30 \uBC14\uB78D\uB2C8\uB2E4."
    }
  })], 1)]), _c('div', {
    staticClass: "main"
  }, [_c('div', {
    staticClass: "main__container"
  }, [!_vm.solutionList.length ? _c('div', {
    staticClass: "data-empty"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/images/admin/icon_empty.svg")
    }
  }), _c('p', [_vm._v("연동된 솔루션 정보가 없습니다.")])]) : _c('div', {
    staticClass: "bothLayout"
  }, _vm._l(_vm.solutionList, function (solution, index) {
    var _solution$userSite, _solution$userSite$at, _solution$userSite2, _solution$userSite2$a;

    return _c('div', {
      key: index,
      staticClass: "bothLayout__item"
    }, [_c('div', {
      staticClass: "userSite"
    }, [solution.userSite ? _c('dl', [_c('dt', [(_solution$userSite = solution.userSite) !== null && _solution$userSite !== void 0 && (_solution$userSite$at = _solution$userSite.attachFiles) !== null && _solution$userSite$at !== void 0 && _solution$userSite$at.url ? _c('img', {
      attrs: {
        "src": (_solution$userSite2 = solution.userSite) === null || _solution$userSite2 === void 0 ? void 0 : (_solution$userSite2$a = _solution$userSite2.attachFiles) === null || _solution$userSite2$a === void 0 ? void 0 : _solution$userSite2$a.url
      }
    }) : _c('span', [_vm._v(_vm._s(solution.userSite.name.slice(0, 1)))])]), _c('dd', {
      staticClass: "cursor userSiteName ga_a_203",
      on: {
        "click": function click($event) {
          $event.stopPropagation();
          return _vm.moveSite(solution.userSite.id);
        }
      }
    }, [_vm._v(" " + _vm._s(solution.userSite.name) + " ")])]) : _c('div', {
      staticClass: "DAV-dropdown"
    }, [_c('button', {
      staticClass: "userSite__button",
      attrs: {
        "type": "button"
      },
      on: {
        "click": function click($event) {
          $event.stopPropagation();
          return _vm.showUserSite(index);
        }
      }
    }, [_vm._v(" 연동할 사이트 선택 "), _c('i', {
      staticClass: "xi-angle-right-min"
    })]), _c('div', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.userSiteLayer[index],
        expression: "userSiteLayer[index]"
      }],
      class: _vm.setDropdownClass
    }, [_c('ul', {
      directives: [{
        name: "click-outside",
        rawName: "v-click-outside:hideUserSite",
        value: index,
        expression: "index",
        arg: "hideUserSite"
      }]
    }, _vm._l(_vm.userSiteList, function (site, i) {
      return _c('li', {
        key: i
      }, [_c('button', {
        attrs: {
          "type": "button"
        },
        on: {
          "click": function click($event) {
            return _vm.selectUserSite(site.id, solution.id);
          }
        }
      }, [_vm._v(" " + _vm._s(site.name) + " ")])]);
    }), 0), _vm.getDeviceType === 'mobile' ? _c('div', {
      staticClass: "dimmed"
    }) : _vm._e()])])]), _c('div', {
      staticClass: "userSolution"
    }, [_c('dl', {
      staticClass: "userSolution__item"
    }, [_c('dt', [_vm._v("솔루션명")]), _c('dd', [solution.solutionName === '메이크샵' ? _c('img', {
      staticStyle: {
        "height": "18px"
      },
      attrs: {
        "src": require("@/assets/images/common/bi_makeshop.svg")
      }
    }) : _vm._e(), solution.solutionName === '마이소호' ? _c('img', {
      staticStyle: {
        "height": "18px"
      },
      attrs: {
        "src": require("@/assets/images/common/bi_mysoho.svg")
      }
    }) : _vm._e(), _c('span', [_vm._v("(ID : " + _vm._s(solution.solutionUserId) + ")")])])]), _c('dl', {
      staticClass: "userSolution__item"
    }, [_c('dt', [_vm._v("연동일시")]), _c('dd', [_vm._v(_vm._s(_vm._f("date")(solution.createdAt)))])]), _c('dl', {
      staticClass: "userSolution__item userSolution__item--break"
    }, [_c('dt', [_vm._v("API Key")]), _c('dd', [_vm._v(_vm._s(solution.apikey))])])])]);
  }), 0)])]), _c('div', {
    staticClass: "footer"
  }, [_vm._v("ⓒ QPICK")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }